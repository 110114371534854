
import { computed, ComputedRef, defineComponent } from 'vue'

export default defineComponent({
  name: 'point-footer',
  components: {},
  props: {
    isAdmin: {
      type: String,
      required: true
    },
    loggedIn: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const cdnUrl = process.env.VUE_APP_CDN_URL

    const isLoggedIn: ComputedRef<boolean> = computed(
      () => props.loggedIn.toLowerCase() === 'true'
    )
    const upperLocation: ComputedRef<string> = computed(() =>
      window.location.pathname.toUpperCase()
    )
    const homeLink: ComputedRef<string> = computed(() =>
      isLoggedIn.value ? '/Secure.aspx' : '/'
    )
    const enableLink = (link: string): boolean => {
      switch (link.toUpperCase()) {
        case 'HOME':
          return upperLocation.value !== homeLink.value
        case 'ABOUT':
          return !upperLocation.value.startsWith('/ABOUT.ASPX')
        case 'FAQ':
          return !upperLocation.value.startsWith('/FAQ.ASPX')
        case 'TERMS':
          return !upperLocation.value.startsWith('/TERMS.ASPX')
        case 'COOKIEPOLICY':
          return !upperLocation.value.startsWith('/COOKIEPOLICY.ASPX')
        case 'ADMIN':
          return !upperLocation.value.startsWith('/ADMIN/ADMIN.ASPX')
        default:
          return false
      }
    }
    return {
      cdnUrl,
      admin: props.isAdmin.toLowerCase() === 'true',
      homeLink,
      currentYear: new Date().getFullYear(),
      enableLink
    }
  }
})
