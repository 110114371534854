
import { computed, ComputedRef, defineComponent } from 'vue'

export default defineComponent({
  name: 'point-header',
  components: {
  },
  props: {
    loggedIn: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const cdnUrl = process.env.VUE_APP_CDN_URL

    const isLoggedIn: ComputedRef<boolean> = computed(() => props.loggedIn.toLowerCase() === 'true')
    const upperLocation: ComputedRef<string> = computed(() => window.location.pathname.toUpperCase())

    const homeLink: ComputedRef<string> = computed(() => isLoggedIn.value ? '/Secure.aspx' : '/')

    const isHome: ComputedRef<boolean> = computed(() => ['/', '/DEFAULT.ASPX', '/SECURE.ASPX'].includes(upperLocation.value))
    const isFaq: ComputedRef<boolean> = computed(() => upperLocation.value.startsWith('/FAQ.ASPX'))
    const isAbout: ComputedRef<boolean> = computed(() => upperLocation.value.startsWith('/ABOUT.ASPX'))
    const isAdmin: ComputedRef<boolean> = computed(() => upperLocation.value.startsWith('/ADMIN/'))

    return {
      cdnUrl,
      isHome,
      isFaq,
      isAbout,
      isAdmin,
      homeLink
    }
  }
})
